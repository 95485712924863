import React, { memo, useEffect, useRef, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import CircularSpinnerWithNumberInside from '@components/shared-ui-components/CircularSpinnerWithNumberInside';
import './styles.scss'
import { showSuccessSnacbar } from '@components/shared-layouts/Snackbar/helper';

interface Step {
  timestamp: string,
  status: string,
  msg: string
}

interface StepsEventsProgressbarProps {
  apiEndpoint: string,
  closeProgressBar?: any
}

const StepsEventsProgressbar = ({ apiEndpoint, closeProgressBar }: StepsEventsProgressbarProps) => {
  const [steps, setSteps] = useState<Step[]>([])
  const [hasReachedEnd, setHasReachedEnd] = useState(false)
  
  const eventSourceRef = useRef<any>()

  const activeStep = steps?.length - 1

  // const getEventsProgressBarStatus = () => {
  //   const eventSource = new EventSource(apiEndpoint);
  //   eventSource.addEventListener('message', (event) => {
  //     const newData = JSON.parse(event.data)
  //     if (newData.status === 'Success') {
  //       setSteps(prevSteps => [...prevSteps, newData])
  //     } else if (newData.status === 'Error') {
  //       setSteps(prevSteps => [...prevSteps, newData])
  //       eventSource.close()
  //       setHasReachedEnd(true)
  //     } else if (newData.status === 'Completed') {
  //       setSteps(prevSteps => [...prevSteps, newData])
  //       eventSource.close()
  //       setHasReachedEnd(true)
  //     }
  //   });
  //   eventSource.addEventListener('error', (e: any) => {
  //     showSuccessSnacbar('Process has been completed')
  //     closeProgressBar && closeProgressBar()
  //     setHasReachedEnd(true)
  //   })
  // }

  // const initialized = useRef(false)
  // useEffect(() => {
  //   if (!initialized.current) {
  //     initialized.current = true
  //     getEventsProgressBarStatus()
  //   }
  //   // return eventSource.close()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  
  useEffect(()=> {
    
    // Initialize SSE connection
    eventSourceRef.current = new EventSource(apiEndpoint);
     
    // Handle incoming messages
    eventSourceRef.current.onmessage = (event: any) => {
      const newData = JSON.parse(event.data)
      if (newData.status === 'Success') {
        setSteps(prevSteps => [...prevSteps, newData])
      } else if (newData.status === 'Error') {
        setSteps(prevSteps => [...prevSteps, newData])
        eventSourceRef.current.close()
        setHasReachedEnd(true)
      } else if (newData.status === 'Completed') {
        setSteps(prevSteps => [...prevSteps, newData])
        eventSourceRef.current.close()
        setHasReachedEnd(true)
      }
    };
    eventSourceRef.current.addEventListener('error', (e: any) => {
      showSuccessSnacbar('Process has been completed')
      closeProgressBar && closeProgressBar()
      setHasReachedEnd(true)
    })
    
    return () => {
      if(eventSourceRef.current) {
        eventSourceRef.current.close()  
      }
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      {!hasReachedEnd && <LinearProgress />}
      <div className="step-progress-bar">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            {index === activeStep && !hasReachedEnd
              ? <div className={`step ${index <= activeStep ? 'active' : ''}`}>
                <CircularSpinnerWithNumberInside number={index + 1} />
                <span className="step-label">{step.msg}</span>
              </div>
              : <div className={`step ${index <= activeStep ? 'active' : ''}`}>
                <span className="step-number" style={{ backgroundColor: step.status === 'Error' ? 'red' : '#ED8622' }}>{index + 1}</span>
                <span className="step-label">{step.msg}</span>
              </div>
            }
          </React.Fragment>
        ))}
      </div>
    </>
  )
}
export default memo(StepsEventsProgressbar)
