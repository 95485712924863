/* eslint-disable no-unused-vars */
import React from 'react'
import { createTheme, SimplePaletteColorOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    futura: React.CSSProperties
    inter: React.CSSProperties
  }

  interface Palette {
    paper: SimplePaletteColorOptions
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    futura?: React.CSSProperties
    inter?: React.CSSProperties
  }

  interface PaletteOptions {
    paper?: SimplePaletteColorOptions
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    futura: true
    inter: true
  }
}

const theme = createTheme({
  typography: {
    futura: {
      fontFamily: 'futura-pt, "Helvetica Neue", Helvetica, Arial, sans-serif'
    },
    inter: {
      fontFamily: 'Inter, "Helvetica Neue", Helvetica, Arial, sans-serif'
    }
  },
  palette: {
    primary: {
      main: '#f04a23'
    },
    secondary: {
      main: '#fcaf17'
    },
    error: {
      main: '#d32f2f'
    },
    // This is to change the disbaled styling
    // action: {
    //   disabled: 'red',
    // },
    paper: {
      main: '#f8f8f8',
      dark: '#ececec'
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, "Helvetica Neue", Helvetica, Arial, sans-serif'
        }
      },
      defaultProps: {
        variantMapping: {
          futura: 'h4'
        }
      }
    },
    //This is to change the mui buttton styling
    MuiButton: {
      styleOverrides: {
        root: {
          "&:disabled": {
            // backgroundColor: '#fcaf17',
            color: '#12193C'
          }

          // backgroundColor: '#fcaf17',
          // color: '#12193C'

        }
      }
    }
  },
})

export default theme
