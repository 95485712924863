import { useEffect, useState, useMemo, useContext } from 'react'
import {
  MaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { useParams } from 'react-router'
import { downloadExportedProjectFiles, getLogFile, getProjectLogs } from '@services/dashboardService';
import { Dialog, Box, DialogActions, Button } from '@mui/material'
import ProgressbarModal from '@components/shared-ui-components/DynamicForm/ProgressbarModal';
import ProjectContext from '@context/Project/projectContext';
import VisualizeInWandb from '@icons/visualize-in-wandb-logo.svg'
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import CachedIcon from '@mui/icons-material/Cached';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

interface ILog {
  key: string;
  process_id: string;
  task_set: string;
  created_date: string;
  ended_time: string;
  running_time: string;
  task_status: string;
}

function LogsPage() {

  const { projectId } = useParams()

  const userToken: any = localStorage.getItem("token") || ""

  const [projectLogs, setProjectLogs] = useState<ILog[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const [showLogsDialog, setShowLogsDialog] = useState<boolean>(false)
  const [showErrorLogsDialog, setShowErrorLogsDialog] = useState<boolean>(false)
  const [logFileContent, setLogFileContent] = useState<any>()

  const projectContextInfo = useContext(ProjectContext);
  const { state: projectState, setState: setProjectState } = projectContextInfo;

  const columns = useMemo<MRT_ColumnDef<ILog>[]>(
    () => [
      {
        header: 'Process Id',
        accessorKey: 'process_id',
        Cell: ({ cell }: any) => <span style={{ color: 'blue' }}>{cell.getValue()}</span>,
      },
      {
        header: 'Task Set',
        accessorKey: 'task_set'
      },
      {
        header: 'Created Date',
        accessorKey: 'created_date'
      },
      {
        header: 'Ended Time',
        accessorKey: 'ended_time'
      },
      {
        header: 'Running Time',
        accessorKey: 'running_time'
      },
      {
        header: 'Task Status',
        accessorKey: 'task_status',
        Cell: ({ cell, row }: any) => {

          const taskStatusValue = cell.getValue()

          const taskSetValue = row.getValue('task_set')

          const processId = row.getValue('process_id')

          switch (taskStatusValue) {
            case 'Failed':
              return <Button variant="contained" color='error' startIcon={<CloseIcon />}>
                Failed
              </Button>
            case 'Completed':
              if (taskSetValue === 'Data Export') return <Button onClick={() => downloadExportedProjectFiles(projectId as string, processId, userToken)} variant="contained" color='success' startIcon={<CloudDownloadIcon />}>
                Download
              </Button>
              return <Button variant="contained" color='success' startIcon={<DoneIcon />}>
                Completed
              </Button>
            case 'Running':
              return <Button variant="contained" color='secondary' startIcon={<CachedIcon />}>
                Running
              </Button>
            default:
              return <a href={taskStatusValue} target='_blank' rel="noreferrer">
                <img
                  style={{
                    width: '150px',
                    cursor: 'pointer'
                  }}
                  src={VisualizeInWandb}
                  alt='visualize-in-wandb-logo'
                />
              </a>

          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getTimestampFromCreatedDate = (createdDate: any) => {
    console.log('Created datae s====>', createdDate)
    const [day, month, year, hour, minute, second] = createdDate.match(/\d+/g);
    const dateObj = new Date(year, month - 1, day, hour, minute, second);
    console.log('dateobj ss', dateObj);
    return dateObj.getTime();
  }

  const calc_time = (start_time: string, end_time: string): string => {
    if (start_time === 'NA' || end_time === 'NA') {
      return '0';
    }
    const startTs = getTimestampFromCreatedDate(start_time)
    const endTs = getTimestampFromCreatedDate(end_time)

    console.log('startts', startTs)
    console.log('endts', endTs)
    const diffInMs = Math.abs(endTs - startTs); // absolute value in case dates are reversed
    console.log("diff times====>", diffInMs)
    const diffInMinutes = Math.floor(diffInMs / 1000); // dividing by 1000 to get seconds, then by 60 to get minutes
    return diffInMinutes.toString();
  }

  const formatProjectLogs = (logs: any) => {
    let _logs: ILog[] = [];
    const currentLogs = logs?.logs.logs;

    console.log('currentl logs ', currentLogs)
    if (currentLogs?.length > 0) {
      currentLogs?.forEach((it: any, idx: any) => {
        // Splitting the string based on '_' separator
        let item = {
          key: `${idx}`,
          process_id: it.pid,
          task_set: it.name,
          created_date: it.created_time,
          ended_time: it.ended_time,
          running_time: calc_time(it.created_time, it.ended_time),
          task_status: it.status,
          error_id: it.error_id
        }
        _logs.push(item)
      });
    }

    setProjectLogs(_logs);
    setLoading(false)
  }

  const fetchErrorLogs = (errorId: string) => {
    setShowErrorLogsDialog(true)
    getLogFile(errorId)
      .then((log: any) => {
        console.log('Here are the logs', log)
        setLogFileContent(log)

      })
  }

  const handleRowClick = (row: any) => {
    if (!projectLogs) return
    const selectRow: any = projectLogs[row.index as number]
    console.log('selected row', selectRow)

    if (selectRow.error_id) {
      // There is error id 
      // dispatch(getLogFileAsync(fullLogItem.error_id))
      fetchErrorLogs(selectRow.error_id)

    } else if (selectRow.task_status === 'Running' || selectRow.task_status === 'running') {
      // IF the process is running 
      // Show steps events progressbar
      setProjectState({
        ...projectState,
        processId: selectRow.process_id
      })
      setShowLogsDialog(true)
    }
  }

  const handleDialogClose = () => {
    setShowLogsDialog(false)
  }

  const handleErrorLogsDialogClose = () => {
    setShowErrorLogsDialog(false)
    setLogFileContent(undefined)
  }


  useEffect(() => {
    if (projectId) {
      // The below will be an api call for the logs
      setLoading(true)
      getProjectLogs(projectId)
        .then((logs: any) => {
          formatProjectLogs(logs)
        })
        .catch((e: any) => {
          console.log(e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      {projectLogs && <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            // size: 120,
          },
        }}
        muiTablePaperProps={{ style: { width: '100%', textTransform: 'capitalize' } }}
        columns={columns}
        data={projectLogs}
        enableColumnOrdering
        positionActionsColumn="last"

        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRowClick(row),
          sx: { cursor: 'pointer' },
        })}
        state={{ isLoading: loading }}
      />}

      {showLogsDialog && <ProgressbarModal open={showLogsDialog} close={handleDialogClose} />}

      {showErrorLogsDialog &&
        <Dialog open={showErrorLogsDialog} onClose={handleErrorLogsDialogClose} fullWidth={true}>
          <Box sx={{ pl: 2, pr: 2, display: 'inline-grid' }}>
            <h4>Error logs</h4>
            <div style={{ fontSize: '14px', fontStyle: 'italic', whiteSpace: 'pre-wrap' }}>

              {logFileContent
                ? `${logFileContent}`
                : 'Loading...'
              }
            </div>
          </Box>
          <DialogActions>
            <Button variant='contained' onClick={handleErrorLogsDialogClose} sx={{ mt: 2 }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

export default LogsPage