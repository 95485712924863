import { SelectProps } from '@mui/material'
import CustomDropdownField from '../CustomDropdownField'
import React from 'react'
import { useParams } from 'react-router-dom'

interface CustomExportFormatFieldProps extends SelectProps {
  formik: any
}

function CustomExportFormatField(props: CustomExportFormatFieldProps) {

  let datasetList = [
    {
      label: 'Coco',
      value: 'COCO'
    },
    {
      label: 'Pascal Voc',
      value: 'PASCAL_VOC'
    },
    {
      label: 'Yolo',
      value: 'YOLO'
    },
    {
      label: 'Genie',
      value: 'GENIE'
    }
  ]
  
  const { project_type } = useParams()
  
  
  if(project_type === 'object_detection_-_(clarifier)') {
    // eslint-disable-next-line no-self-assign
    datasetList = datasetList // It will be the same for this project type
  }
  
  if(project_type === 'instance_segmentation_-_(clarifier)') {
    datasetList = [{label: 'Coco',value: 'COCO'},{label: 'Yolo',value: 'YOLO'},{label: 'Genie',value: 'GENIE'}]
  }
  
  if(project_type === 'image_classification_-_(clarifier)') {
    datasetList = [{label: 'Genie',value: 'GENIE'}]
  }
  
  if(project_type === 'semantic_segmentation_-_(clarifier)') {
    datasetList = [{label: 'Genie',value: 'GENIE'}]
  }

  return (
    <CustomDropdownField
      dropdownData={datasetList}
      {...props}
    />
  )
}

export default React.memo(CustomExportFormatField)