import { showErrorSnackbar, showSuccessSnacbar } from '@components/shared-layouts/Snackbar/helper'
import { CustomSubmitButton } from '@components/shared-ui-components/Buttons'
import { CustomNumberField, CustomSwitchField } from '@components/shared-ui-components/CustomFields'
import OperationSuccessfullyCompletedModal from '@components/shared-ui-components/DynamicForm/OperationSuccessfullyCompletedModal'
import ProgressbarModal from '@components/shared-ui-components/DynamicForm/ProgressbarModal'
import { getDynamicFormValidationSchema } from '@components/shared-ui-components/DynamicForm/helper'
import ProjectContext from '@context/Project/projectContext'
import { Paper } from '@mui/material'
import { performRelevanceDataSelection } from '@services/dashboardService'
import { getProjectType } from '@utils/index'
import { FormikHelpers, useFormik } from 'formik'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import UploadDataPage from './UploadDataPage'

const initialDefaultValues = {
  
  query_link: '',
  target_link: '',
  
  query_compressed_file: undefined,
  target_compressed_file: undefined,
  
  num_samples: 0,
  replace_existing_instances: false
}

function RelevantDataSelectionPage() {
  
  const page = 'relevantDataSelection'
  
  const { projectId, project_type } = useParams();
  
  const projectType = getProjectType()
  
  const [initialValues, setInitialValues] = useState(initialDefaultValues);
  
  const [operationSuccessModal, setOperationSuccessModal] = useState<boolean>(false);

  const [checkLiveStatusModal, setCheckLiveStatusModal] = useState<boolean>(false);
  
  const projectContextInfo = useContext(ProjectContext);
  const { state: projectState, setState: setProjectState } = projectContextInfo;
  
  
  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    
    console.log('handle submit is running')
    
    // Handle form submission
    console.log("submit Values ====>", values);
    values["project_id"] = projectId;
    values['project_type'] = project_type
    // Over here we will get api function to be call
    performRelevanceDataSelection(values)
      .then((x: any) => {
        console.log("Response from api", x);
        // Reset the form after success
        actions.resetForm();
        // Enable the form
        actions.setSubmitting(false);
        // Set ProjectContext 
        setProjectState({
          ...projectState,
          processId: x.process_id,
        });
        // show a notification progress has been save
        showSuccessSnacbar(x.message);
        // Show Success Modal
        setOperationSuccessModal(true);
        
        setInitialValues(initialDefaultValues)
      })
      .catch((e: any) => {
        // Over here we need to handle the status codes ===>
        console.log("THis is the error coming", e);
        // showErrorSnackbar
        if (e.status === 400) {
          showErrorSnackbar(e.error.message);
        }
        actions.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true, 
    validationSchema: getDynamicFormValidationSchema(page, projectType), // We just need to handle the logic for the validation schema passed here  **** VERY VERY IMPORTANT *****
    onSubmit: handleSubmit,
  });

  const {
    handleSubmit: handleSub,
    isSubmitting,
    errors,
    values,
    touched,
    handleChange
  } = formik || {};

  const handleCheckLiveStatus = () => {
    // First we close the operation successful Modal
    setOperationSuccessModal(false);
    setCheckLiveStatusModal(true);
  };
  
  
  const handleOperationModalClose = () => {
    setOperationSuccessModal(false);
  };

  const handleCheckLiveStatusModalClose = () => {
    setCheckLiveStatusModal(false);
  };

  
  console.log('valuessss----', values, errors)
  
  return (
    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0px'
      }}
    >
      <h4>Please fill up the Relevant Data Selection form</h4>
     <form onSubmit={handleSub} style={{ display: "inline-grid" }}>
      
        <UploadDataPage formik={formik}/>

        <CustomNumberField
          label={'Number of samples'}
          name={'num_samples'}
          value={values['num_samples']}
          onChange={handleChange}
          error={Boolean(touched['num_samples'] && errors['num_samples'])}
          sx={{ mb: 3, mt: 3 }}
          InputProps={{ inputProps: { min: 0} }}
          disabled={isSubmitting}
          
        />
        
           
        <CustomSwitchField
          label={'Replace existing instances'}
          name={'replace_existing_instances'}
          value={values['replace_existing_instances']}
          onChange={(e: any) => handleChange}
          error={Boolean(touched['replace_existing_instances'] && errors['replace_existing_instances'])}
          sx={{ mb: 2 }}
          disabled={isSubmitting}
        /> 
        <CustomSubmitButton btnName="Submit" isSubmitting={isSubmitting} />
      
      </form>
      
      {/* Dialog for showing the Operation Successful Modal */}
      <OperationSuccessfullyCompletedModal
          open={operationSuccessModal}
          close={handleOperationModalClose}
          handleCheckLiveStatus={handleCheckLiveStatus}
        />
        {/* Dialog for Showing the Check Live status  */}
        {checkLiveStatusModal && (
          <ProgressbarModal
            page={page}
            open={checkLiveStatusModal}
            close={handleCheckLiveStatusModalClose}
          />
        )}
      
      
    </Paper>
  )
}

export default RelevantDataSelectionPage