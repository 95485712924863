import { useState } from 'react'
import { CustomFileUpload, CustomTextField } from "@components/shared-ui-components/CustomFields";
import { ErrorHelper as ErrorHelperWrapper } from '@components/shared-ui-components/CustomFields';
import { Box, Button } from '@mui/material'


function UploadTargetData({formik}: any) {

  const [files, setFiles] = useState<File[]>([]);

  const { errors, isSubmitting, touched, values } = formik || {}

  const handleSetFiles = (fs: File[]) => {
    setFiles(fs);
    formik.setFieldValue('target_compressed_file', fs[0])
  }

  const handleUrl = (url: string) => {
    files.length > 0 && setFiles([]);
    formik.setFieldValue('target_link', url)
  }

  return (
    <>
      <h4>Upload Target Data</h4>
        <Box>
          <CustomFileUpload files={values['target_compressed_file']} setFiles={handleSetFiles} />
          <ErrorHelperWrapper isError={!!(touched.compressed_file && errors.compressed_file)} errorMsg={errors.compressed_file} helperId='compressed_file-create-project' />
          <input id="file" name="target_compressed_file" type="file" hidden />
          <h4>Or</h4>
          <CustomTextField
            placeholder="Input File link"
            sx={{ mb: 2 }}
            fullWidth
            name="target_link"
            value={files.length === 0 ? values.target_link : ''}
            onChange={(e) => handleUrl(e.target.value)}
          />
          <Box sx={{ mb: 2 }}>
            <div>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                sx={{ mt: 1, mr: 1 }}
              >
                {`Upload`}
              </Button>

            </div>
          </Box>
        </Box>
    </>
  )
}

export default UploadTargetData