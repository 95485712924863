/* eslint-disable @typescript-eslint/no-unused-vars */
import CustomTextField from '../CustomFields/CustomTextField'
import CustomDataSetField from '../CustomFields/CustomDatasetField'
import CustomModelListField from '../CustomFields/CustomModelListField'
import CustomSelectEvaluationField from '../CustomFields/CustomSelectEvaluationField'
import CustomNumberField from '../CustomFields/CustomNumberField'
import CustomSwitchField from '../CustomFields/CustomSwitchField'
import * as yup from 'yup'
import { performDataLabellingSelection, performActiveSelection, performAugmentation, performAutoLabelling, performDiverseSelection, performEvaluation, performTargetSelection, performTraining, performInference, exportProjectFiles } from '@services/dashboardService'
import { FormikProps } from 'formik'
import { CustomFileUpload } from '../CustomFields'
import CustomLabelsField from '../CustomFields/CustomLabelsField'
import CustomLabelledDatasetField from '../CustomFields/CustomLabelledDatasetField'
import CustomUnLabelledDatasetField from '../CustomFields/CustomUnLabelledDatasetField'
import CustomSelectAugmentationField from '../CustomFields/Augmentation/CustomAugmentationField'
import CustomCropBlursField from '../CustomFields/Augmentation/CustomCropBlursField'
import CustomGeometricTransformsField from '../CustomFields/Augmentation/CustomGeometricTransformsField'
import CustomPixelTransformsField from '../CustomFields/Augmentation/CustomPixelTransformsField'
import CustomExportFormatField from '../CustomFields/CustomExportFormatField'
import CustomStrategyField from '../CustomFields/CustomStrategyField'
import CustomClarifierDatasetField from '../CustomFields/Clarifier/ClarifierPage/CustomClarifierDatasetField'
import CustomAlgorithmField from '../CustomFields/Clarifier/AutoLabellingPage/CustomAlgorithmField'

// With no project type it currently only shows for the object detection

export const dynamicFormJson: any = {
  dataLabellingSelection: [
    { type: 'dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '' },
    { type: 'dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'image_classification' },  
    { type: 'clarifier_dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'image_classification_-_(clarifier)' },  
    { type: 'clarifier_dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'object_detection_-_(clarifier)' },
    { type: 'clarifier_dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'instance_segmentation_-_(clarifier)' },
    { type: 'clarifier_dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'semantic_segmentation_-_(clarifier)' },
  ],
  diverseSelection: [
    { type: 'unLabelledDataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '' },
    { type: 'switch', name: 'default_settings', label: 'Use default settings', defaultValue: true }, // this will also be toggle
    { type: 'number', subType: 'modal', name: 'num_images', label: 'Number of images', defaultValue: 8 },
    { type: 'modelList', subType: 'modal', name: 'model', label: 'Model', defaultValue: 'resnet18' },
    // { type: 'switch', subType: 'modal', name: 'use_cache', label: 'Use Cache', defaultValue: true }
  ],
  augmentation: [
    { type: 'augmentationList', name: 'augmentation', label: 'Select Type Augmentation' },
    { type: 'labelledDataset', name: 'src_dataset', label: 'Select the dataset', dependent: 'augmentation', dependentValue: 'augmentation' },
    { type: 'number', name: 'num_in_images', label: 'Number of Input images', dependent: 'augmentation', dependentValue: 'augmentation' },
    { type: 'number', name: 'num_out_images', label: 'Number of output images', dependent: 'augmentation', dependentValue: 'augmentation' },
    { type: 'number', name: 'min_img_sizee', label: 'Minimum Image Size', dependent: 'augmentation', dependentValue: 'augmentation' },
    { type: 'number', name: 'max_img_sizee', label: 'Maximum Image Size', dependent: 'augmentation', dependentValue: 'augmentation' },
    { type: 'pixelTransformsList', name: 'pixel_transforms', label: 'Select Pixel Transforms', dependent: 'augmentation', dependentValue: 'augmentation', multiple: true },
    { type: 'cropBlursList', name: 'crop_blurs', label: 'Select Crop Blurs', dependent: 'augmentation', dependentValue: 'augmentation', multiple: true },
    { type: 'geometricTransformsList', name: 'geometric_transforms', label: 'Select Geometric Transforms', dependent: 'augmentation', dependentValue: 'augmentation', multiple: true },
    // FOR COPY PASTE AUGMENTATION
    { type: 'labels', name: 'class_name', label: 'List of Target Classes', dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'file', name: 'bg_file', label: 'Select the Background file', defaultValue: [], dependent: 'augmentation', dependentValue: 'copyPasteAugmentation', }, // Should be file upload only
    { type: 'file', name: 'fg_file', label: 'Select the Foreground file', defaultValue: [], dependent: 'augmentation', dependentValue: 'copyPasteAugmentation', }, // Should be file upload only

    // Extra options for the copy paste
    { type: 'switch', name: 'default_settings', label: 'Use default settings', defaultValue: true, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' }, // this will also be toggle

    { type: 'number', subType: 'modal', name: 'min_img_size', label: 'Minimum Image Size', defaultValue: 900, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'max_img_size', label: 'Maximum Image Size', defaultValue: 900, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'min_obj_range', label: 'Minimum Object Range', defaultValue: 1, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'max_obj_range', label: 'Maximum Object Range', defaultValue: 1, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'min_x_range', label: 'Minimum X Range', defaultValue: 100, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'max_x_range', label: 'Maximum X Range', defaultValue: 500, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'min_y_range', label: 'Minimum Y Range', defaultValue: 100, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'max_y_range', label: 'Maximum Y Range', defaultValue: 500, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'obj_width', label: 'Object Width', defaultValue: 50, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' },
    { type: 'number', subType: 'modal', name: 'obj_height', label: 'Object Height', defaultValue: 50, dependent: 'augmentation', dependentValue: 'copyPasteAugmentation' }
    // { type: 'file', name: 'config', label: 'Select the configuration file', defaultValue: [] } // Should be file upload only
  ],
  autoLabelling: [
    { type: 'unLabelledDataset', name: 'src_dataset', label: 'Select the dataset' },
    { type: 'customAlgorithmField', name: 'algorithm', label: 'Select the Algorithm' },
    { type: 'string', name: 'model_1_wandb_run_id', label: 'Enter WandB Model ID', dependent: 'algorithm', dependentValue: 'Consensus'},
    { type: 'string', name: 'model_2_wandb_run_id', label: 'Enter WandB Auxiliary Model ID', dependent: 'algorithm', dependentValue: 'Consensus' },
    // { type: 'string', name: 'model_1_wandb_run_id', label: 'Enter WandB Model ID' },
    // { type: 'string', name: 'model_2_wandb_run_id', label: 'Enter WandB Auxiliary Model ID' }
  ],
  training: [
    { type: 'labelledDataset', name: 'train_sets', label: 'Select the training set', multiple: true },
    // { type: 'labelledDataset', name: 'val_sets', label: 'Select the validation set', multiple: true },
    { type: 'modelList', name: 'model_name', label: 'Select Model' },
    { type: 'switch', name: 'default_settings', label: 'Use default settings', defaultValue: true }, // this will be toggle 
    { type: 'string', subType: 'modal', name: 'wandb_project', label: 'WandB Project', defaultValue: 'test_2' },
    { type: 'number', subType: 'modal', name: 'seed', label: 'Seed', defaultValue: 42 },
    // { type: 'number', subType: 'modal', name: 'val_ratio_per_set', label: 'Val Ratio Per Set', defaultValue: 0.2 },
    { type: 'switch', subType: 'modal', name: 'shuffle', label: 'Shuffle', defaultValue: true }
  ],
  evaluation: [
    { type: 'evaluationList', name: 'evaluation', label: 'Select Evaluation' },
    { type: 'dataset', name: 'src_dataset', label: 'Select the dataset', dependent: 'evaluation', dependentValue: 'automatedEvaluation' },
    // { type: 'dataset', name: 'src_dataset', label: 'Select the dataset' },
    { type: 'string', name: 'wandb_run_id', label: 'Enter WandB run ID' },
    // The below will check if the evaluation  value is auto evaluation then it will render
    { type: 'string', name: 'wandb_aux_run_id', label: 'Enter WandB Auxiliary ID', dependent: 'evaluation', dependentValue: 'automatedEvaluation' },
  ],
  activeSelection: [
    { type: 'strategyList', name: 'strategy', label: 'Strategy', projectType: 'image_classification' },
    { type: 'string', name: 'iteration_name', label: 'Name of the iteration' },
    { type: 'string', name: 'wandb_model_id', label: 'Enter WandB model ID' },
    { type: 'string', name: 'wandb_model_id', label: 'Enter WandB model ID', projectType: 'image_classification' },
    { type: 'unLabelledDataset', name: 'src_dataset', label: 'Select the dataset' },
    { type: 'unLabelledDataset', name: 'unlabelled_dataset', label: 'Select the dataset', projectType: 'image_classification' },
    { type: 'labelledDataset', name: 'labelled_dataset', label: 'Select the labelled dataset', projectType: 'image_classification' },
    { type: 'number', name: 'num_output_images', label: 'Number of output images', projectType: 'image_classification' },
  ],
  targetSelection: [
    { type: 'string', name: 'iteration_name', label: 'Name of the iteration' },
    { type: 'string', name: 'wandb_model_id', label: 'Enter WandB model ID' },
    { type: 'labels', name: 'target_classes', label: 'List of Target Classes', multiple: true },
    { type: 'labelledDataset', name: 'labelled_dataset', label: 'Select the labelled dataset' },
    { type: 'unLabelledDataset', name: 'unlabelled_dataset', label: 'Select the Unlabelled dataset' },
    { type: 'number', name: 'num_output_images', label: 'Number of output images' }
  ],
  inference: [
    { type: 'string', name: 'wandb_id', label: 'Enter WandB ID', style: { paddingBottom: 0, marginBottom: 0 } },
    { type: 'htmlParagraph', name: 'nothing', label: 'Please select the source of data for the Inference ?', style: { padding: '16px 0px', margin: 0, marginTop: '10px', fontWeight: 'bold', fontStyle: 'italic', color: '#ed8726', fontSize: '15px' } },

    { type: 'unLabelledDataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', style: { paddingBottom: 0, marginBottom: 0 }, disableDependent: ['link', 'data_source'] },
    { type: 'htmlParagraph', name: 'nothing', label: 'Or', style: { padding: '8px 0px', margin: 0, fontWeight: 'bold', textAlign: 'center' } },
    { type: 'string', name: 'link', label: 'Input file Link', style: { paddingBottom: 0, marginBottom: 0 }, disableDependent: ['src_dataset', 'data_source'] },
    { type: 'htmlParagraph', name: 'nothing', label: 'Or', style: { padding: '8px 0px', margin: 0, fontWeight: 'bold', textAlign: 'center' } },
    { type: 'file', name: 'data_source', label: 'Select the Data source file', defaultValue: [], disableDependent: ['src_dataset', 'link'] }, // Should be file upload only
  ],
  export: [
    { type: 'dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '' },
    { type: 'dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'image_classification' },  
    { type: 'clarifier_dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'image_classification_-_(clarifier)' },  
    { type: 'clarifier_dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'object_detection_-_(clarifier)' },
    { type: 'clarifier_dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'instance_segmentation_-_(clarifier)' },
    { type: 'clarifier_dataset', name: 'src_dataset', label: 'Select the dataset', defaultValue: '', projectType: 'semantic_segmentation_-_(clarifier)' },
    { type: 'exportFormatList', isSpecialArrayDependent: true, name: 'export_format', label: 'Select the Export Format', dependent: 'src_dataset', dependentValue: ['labelled_set', 'augmented_set', 'auto_labelled_set', 'evaluation_set'] }, // Should be file upload only
    { type: 'switch', isSpecialArrayDependent: true, name: 'export_only_annotations', label: 'Export Only Annotations?', dependent: 'src_dataset', dependentValue: ['labelled_set', 'augmented_set', 'auto_labelled_set', 'evaluation_set'], defaultValue: false },
    { type: 'exportFormatList', isSpecialArrayDependent: true, name: 'export_format', label: 'Select the Export Format', dependent: 'src_dataset', dependentValue: ['labelled_set', 'augmented_set', 'auto_labelled_set', 'evaluation_set'], projectType: 'object_detection_-_(clarifier)' },
    { type: 'switch', isSpecialArrayDependent: true, name: 'export_only_annotations', label: 'Export Only Annotations?', dependent: 'src_dataset', dependentValue: ['labelled_set', 'augmented_set', 'auto_labelled_set', 'evaluation_set'], defaultValue: false, projectType: 'object_detection_-_(clarifier)' },
    { type: 'exportFormatList', isSpecialArrayDependent: true, name: 'export_format', label: 'Select the Export Format', dependent: 'src_dataset', dependentValue: ['labelled_set', 'augmented_set', 'auto_labelled_set', 'evaluation_set'], projectType: 'instance_segmentation_-_(clarifier)' },
    { type: 'switch', isSpecialArrayDependent: true, name: 'export_only_annotations', label: 'Export Only Annotations?', dependent: 'src_dataset', dependentValue: ['labelled_set', 'augmented_set', 'auto_labelled_set', 'evaluation_set'], defaultValue: false, projectType: 'instance_segmentation_-_(clarifier)' },
    { type: 'exportFormatList', isSpecialArrayDependent: true, name: 'export_format', label: 'Select the Export Format', dependent: 'src_dataset', dependentValue: ['labelled_set', 'augmented_set', 'auto_labelled_set', 'evaluation_set'], projectType: 'semantic_segmentation_-_(clarifier)' },
    { type: 'switch', isSpecialArrayDependent: true, name: 'export_only_annotations', label: 'Export Only Annotations?', dependent: 'src_dataset', dependentValue: ['labelled_set', 'augmented_set', 'auto_labelled_set', 'evaluation_set'], defaultValue: false, projectType: 'semantic_segmentation_-_(clarifier)' }
  ],
}

// For setting dynamic for initial values of formik
export const getDynamicFormInitialValues = (page: string) => {
  let initialValues: any = {}
  // Setting the initial values for the json 
  dynamicFormJson[page]?.map((x: any) => {
    if (x.type === 'switch') {
      return initialValues[x.name] = x?.defaultValue
    }

    if (x.type === 'dataset' && x.multiple) {
      return initialValues[x.name] = []
    }

    if (x.type === 'labelledDataset' && x.multiple) {
      return initialValues[x.name] = []
    }

    if (x.type === 'unLabelledDataset' && x.multiple) {
      return initialValues[x.name] = []
    }

    if (x.type === 'labels' && x.multiple) {
      return initialValues[x.name] = []
    }

    if (x.type === 'cropBlursList' && x.multiple) {
      return initialValues[x.name] = []
    }
    if (x.type === 'geometricTransformsList' && x.multiple) {
      return initialValues[x.name] = []
    }
    if (x.type === 'pixelTransformsList' && x.multiple) {
      return initialValues[x.name] = []
    }

    return initialValues[x.name] = x?.defaultValue || ''
  })

  return initialValues
}



export const dynamicFormValidationSchema: any = {

  dataLabellingSelection: yup.object().shape({
    src_dataset: yup.string().required('Please select dataset type')
  }),

  diverseSelection: yup.object().shape({
    src_dataset: yup.string().required('Please select dataset type')
  }),

  augmentation: yup.object().shape({
    // src_dataset: yup.string().required('Please select dataset type'),
  }),

  autoLabelling: yup.object().shape({
    src_dataset: yup.string().required('Please select dataset type'),
    model_1_wandb_run_id: yup.string().required('Model 1 WandB run id is required'),
    model_2_wandb_run_id: yup.string().required('Model 2 WandB run id is required')
  }),
  
  image_classificationautoLabelling: yup.object().shape({
    src_dataset: yup.string().required('Please select dataset type'),
    model_1_wandb_run_id: yup.string().required('Model 1 WandB run id is required'),
    model_2_wandb_run_id: yup.string().required('Model 2 WandB run id is required')
  }),

  training: yup.object().shape({
    train_sets: yup.array().required('Please select Training Set type'),
    // val_sets: yup.array().required('Please select Validation Set type'),
    model_name: yup.string().required('Please select model name')
    // wandb_project: yup.string().required('WandB Project is required'),
    // seed: yup.number().required('Seed is required')
    // val_ratio_per_set: yup.number().required('Seed is required')
    // shuffle is a default value toggle so validation NA
  }),

  evaluation: yup.object().shape({
    evaluation: yup.string().required('Please select Evaluation type'),
    // src_dataset: yup.string().required('Please select dataset type'),
    src_dataset: yup.string().test('conditionalValidation', 'Src Dataset is required when evaluation is Automated Evaluation', function (value) {
      const field1Value = this.resolve(yup.ref('evaluation'));
      if (field1Value === 'automatedEvaluation') {
        return value !== undefined && value !== '';
      }
      return true;
    }),
    wandb_run_id: yup.string().required('WandB Run ID is required'),
    wandb_aux_run_id: yup.string().test('conditionalValidation', 'WandB Auxiliary ID is required when evaluation is Automated Evaluation', function (value) {
      const field1Value = this.resolve(yup.ref('evaluation'));
      if (field1Value === 'automatedEvaluation') {
        return value !== undefined && value !== '';
      }
      return true;
    })
  }),

  activeSelection: yup.object().shape({
    iteration_name: yup.string().required('Name of the iteration is required'),
    wandb_model_id: yup.string().required('WandB model ID is required'),
    src_dataset: yup.string().required('Please select dataset type'),
    num_output_images: yup.number().required('Number of output images is required')
  }),
  
  image_classificationactiveSelection: yup.object().shape({
    iteration_name: yup.string().required('Name of the iteration is required'),
    wandb_model_id: yup.string().required('WandB model ID is required'),
    src_dataset: yup.string().required('Please select dataset type'),
    num_output_images: yup.number().required('Number of output images is required')
  }),

  targetSelection: yup.object().shape({
    iteration_name: yup.string().required('Name of the iteration is required'),
    wandb_model_id: yup.string().required('WandB model ID is required'),
    target_classes: yup.array().required('Please select Target Classes'),
    labelled_dataset: yup.string().required('Please select labelled dataset type'),
    unlabelled_dataset: yup.string().required('Please select labelled dataset type'),
    num_output_images: yup.number().required('Number of output images is required')
  }),

  inference: yup.object().shape({
    wandb_id: yup.string().required('WandB model ID is required'),
    src_dataset: yup.string(),
    link: yup.string()
  }),
  
  clarifier: yup.object().shape({
    base_model: yup.string().required('Base Model is required').min(1, 'Base Model cannot be empty'),
    hard_strategy: yup.string().required('Hard Strategy is required'),
    medium_strategy: yup.string().required('Medium Strategy is required'),
    easy_strategy: yup.string().required('Easy Strategy is required'),
    auto_lb_strategy: yup.string().required('Auto lib Strategy is required'),
    hard_budget: yup.number().required('Hard Budget is required'),
    medium_budget: yup.number().required('Medium Budget is required'),
    easy_budget: yup.number().required('Easy Budget is required'),
    adaptive: yup.boolean().required('Adaptive is required'),
    force_start: yup.boolean().required('Force Start is required'),
  })
}

export const getDynamicFormValidationSchema = (page: string, projectType?: string) => {
  if(projectType !== 'image_classification') {
    if(page === 'activeSelection' || page === 'autoLabelling') {
      return dynamicFormValidationSchema[`${projectType}${page}`]
    } else return dynamicFormValidationSchema[page]
  }
  
  return dynamicFormValidationSchema[page]
}

interface IrenderFormFieldsValues {
  showModalFields: boolean,
  page: string,
  formik: FormikProps<any>,
  handleFieldChange: any,
  setDialogOpenStatus: any,
  files?: any,
  setFiles?: any
}

export const renderFormFields = (renderFormFieldsValues: IrenderFormFieldsValues) => {

  const { showModalFields = false, page, formik, handleFieldChange, setDialogOpenStatus } = renderFormFieldsValues

  const { values, touched, errors, handleChange: formikHandleChange, isSubmitting, setFieldValue } = formik

  const projectType = localStorage
      .getItem("projectType")
      ?.toLowerCase()
      .replace(/ /g, "_");

  const handleChange = (e: any) => {
    const { name, value } = e.target
    formikHandleChange(e)
    if (handleFieldChange) handleFieldChange(name, value)
    if (name === 'default_settings') {
      // ** Remember values.default_settings is opposite
      if (values.default_settings) setDialogOpenStatus(true)
      // ===================TODO ============================
      // RESET ALL THE MODAL VALUES TO FORMIK INITIAL VALUES AS THE DEFAULT SETTING HAS BEEN TURNED ON
    }
  }

  const customHandleSetFiles = (files: any, fieldName: string) => {
    setFieldValue(fieldName, files)
  }

  return dynamicFormJson[page]?.map((x: any) => {


    if(projectType === 'image_classification') {
      if(page === 'activeSelection' || page === 'dataLabellingSelection' || page === 'export') {
        if(x.projectType !== projectType) return ''  
      }
    } else if(projectType === 'object_detection') {
      
      if(page === 'activeSelection' || page === 'dataLabellingSelection' || 'export') {
        
        if (x.projectType) return ''  
      }
    }
    
    if(projectType === 'image_classification_-_(clarifier)' || projectType === 'object_detection_-_(clarifier)' || projectType === 'instance_segmentation_-_(clarifier)' || projectType === 'semantic_segmentation_-_(clarifier)') {
      
      if( page === 'dataLabellingSelection' || page === 'export') {
        if(x.projectType !== projectType) return ''
      }
    }
    
    if (!showModalFields && x.subType === 'modal') return ''
    if (showModalFields && x.subType !== 'modal') return ''

    // check access and then chectype and return 
    // dependent: 'evaluation', dependentValue: 'autoevaluation'
    if (x.dependent) {
      const valueOfDependentField = values[x.dependent]
      if (x.isSpecialArrayDependent) {
        if (!x.dependentValue.includes(valueOfDependentField)) return ''
      } else {
        if (valueOfDependentField !== x.dependentValue) return ''
      }
    }

    let isDisabled = false

    if (x?.disableDependent) {

      // For each field of disable dependent run a loop and check if it has a value

      x.disableDependent.forEach((y: string) => {
        const valueOfDisableDependentField = values[y]
        console.log(`value of ${y} - typeof is ${typeof (valueOfDisableDependentField)}`, valueOfDisableDependentField)
        const typeOfValue = typeof (valueOfDisableDependentField)
        if (typeOfValue === 'string') {
          if (valueOfDisableDependentField !== '') isDisabled = true
        } else if (typeOfValue === 'object') {
          if (valueOfDisableDependentField?.length !== 0) isDisabled = true
        }
      })
    }


    // check type and return
    switch (x.type) {
      case 'string':
        return <CustomTextField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          sx={{ mb: 2 }}
          disabled={isSubmitting || isDisabled}
          style={x?.style}
        />
      case 'evaluationList':
        return <CustomSelectEvaluationField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          formik={formik}
          disabled={isSubmitting}
        />
      case 'augmentationList':
        return <CustomSelectAugmentationField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          formik={formik}
          disabled={isSubmitting}
        />
      case 'cropBlursList':
        return <CustomCropBlursField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          formik={formik}
          disabled={isSubmitting}
          multiple={true}
        />
      case 'geometricTransformsList':
        return <CustomGeometricTransformsField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          formik={formik}
          disabled={isSubmitting}
          multiple={true}
        />
      case 'pixelTransformsList':
        return <CustomPixelTransformsField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          formik={formik}
          disabled={isSubmitting}
          multiple={true}
        />
      case 'dataset':
        return <CustomDataSetField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          multiple={x?.multiple}
          formik={formik}
          disabled={isSubmitting || isDisabled}
          style={x.style}
        />
      case 'clarifier_dataset':
        return <CustomClarifierDatasetField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          multiple={x?.multiple}
          formik={formik}
          disabled={isSubmitting || isDisabled}
          style={x.style}
        />
      case 'customAlgorithmField':
          return <CustomAlgorithmField
            key={x.name}
            label={x.label}
            name={x.name}
            value={values[x.name]}
            onChange={handleChange}
            error={Boolean(touched[x.name] && errors[x.name])}
            multiple={x?.multiple}
            formik={formik}
            disabled={isSubmitting || isDisabled}
            style={x.style}
          />
      case 'labelledDataset':
        return <CustomLabelledDatasetField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          multiple={x?.multiple}
          formik={formik}
          disabled={isSubmitting}
          page={page}
        />
      case 'unLabelledDataset':
        return <CustomUnLabelledDatasetField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          multiple={x?.multiple}
          formik={formik}
          disabled={isSubmitting}
          page={page}
        />
      case 'modelList':
        return <CustomModelListField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          formik={formik}
          disabled={isSubmitting}
          modelListName={page}
        />
      case 'number':
        return <CustomNumberField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          error={Boolean(touched[x.name] && errors[x.name])}
          onChange={handleChange}
          disabled={isSubmitting}
        />
      case 'switch':
        return <CustomSwitchField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          disabled={isSubmitting}
        />
      case 'file':
        return <CustomFileUpload
          files={values[x.name]}
          key={x.name}
          label={x.label}
          name={x.name}
          disabled={isSubmitting || isDisabled}
          setFiles={customHandleSetFiles}
        />
      case 'labels':
        return <CustomLabelsField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          multiple={x?.multiple}
          formik={formik}
          disabled={isSubmitting}
        />
      case 'exportFormatList':
        return <CustomExportFormatField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          formik={formik}
          disabled={isSubmitting}
        />
      case 'strategyList':
        return <CustomStrategyField
          key={x.name}
          label={x.label}
          name={x.name}
          value={values[x.name]}
          onChange={handleChange}
          error={Boolean(touched[x.name] && errors[x.name])}
          formik={formik}
          disabled={isSubmitting}
        />
      case 'htmlParagraph':
        return <p style={x.style}>{x.label}</p>
      default:
        return <p key={x.name} style={x.style}>{x.type}</p>;
    }
  })
}

export const callDynamicFormApi = (page: string) => {
  switch (page) {
    case 'dataLabellingSelection':
      return performDataLabellingSelection
    case 'diverseSelection':
      return performDiverseSelection
    case 'augmentation':
      return performAugmentation
    case 'autoLabelling':
      return performAutoLabelling
    case 'training':
      return performTraining
    case 'evaluation':
      return performEvaluation
    case 'activeSelection':
      return performActiveSelection
    case 'targetSelection':
      return performTargetSelection
    case 'inference':
      return performInference
    case 'export':
      return exportProjectFiles
    default:
      return performDiverseSelection
  }
}

export const getStepsProgressbarApiEndpoint = (page: string | undefined) => {

  const baseUrl = process.env.REACT_APP_BASE_API

  const finalUrl = `${baseUrl}/operations/live_status`

  switch (page) {
    case 'diverseSelection':
      // ********* WE NEED TO REMOVE THE LAST ID *******************
      return `${finalUrl}`
    case 'augmentation':
      return `${finalUrl}`
    case 'autoLabelling':
      return `${finalUrl}`
    case 'training':
      return `${finalUrl}`
    case 'evaluation':
      return `${finalUrl}`
    case 'activeSelection':
      return `${finalUrl}`
    case 'targetSelection':
      return `${finalUrl}`
    case 'uploadUnlabelledSet':
      return `${finalUrl}`
    case 'uploadLabelledSet':
      return `${finalUrl}`
    case 'clarifier':
      return `${finalUrl}`
    case 'relevantDataSelection':
      return `${finalUrl}`
    case 'inference':
      return `${finalUrl}`
    default:
      return `${finalUrl}`
  }
}