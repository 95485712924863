import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function CustomSwitchField(props: any) {
  return (
    <FormControlLabel
      {...props}
      control={<Switch checked={props.value} />}
      labelPlacement='start'
      style={{ width: 'fit-content', marginLeft: '0', marginBottom: '20px' }}
    />
  )
}

export default CustomSwitchField