export const getLocalUrlFromBase64 = async (imageBase64Data: string) => {
  // Create a blob from the base64 image data
  const blob = await (await fetch(imageBase64Data, {credentials: 'include'})).blob();
  console.log('blob from the other', blob)
  // Create a local file URL from the blob
  const localUrl = URL.createObjectURL(blob);
  // Save the image to local storage if needed
  localStorage.setItem("imageData", JSON.stringify(localUrl));
  return localUrl;
}

export const saveImageToLocal = (base64String: string) => {
  const byteString = atob(base64String.split(',')[1]);
  const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });
  const url = URL.createObjectURL(blob);
  return url;
}