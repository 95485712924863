import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import {
  callDynamicFormApi,
  getDynamicFormInitialValues,
  getDynamicFormValidationSchema,
  renderFormFields,
} from "./helper";
import CircularProgress from "@mui/material/CircularProgress";
import {
  showErrorSnackbar,
  showSuccessSnacbar,
} from "@components/shared-layouts/Snackbar/helper";
import ProgressbarModal from "./ProgressbarModal";
import OperationSuccessfullyCompletedModal from "./OperationSuccessfullyCompletedModal";
import { useParams } from "react-router-dom";
import ProjectContext from "@context/Project/projectContext";

interface DynamicFormProps {
  page: string;
  handleFieldChange?: (fieldName: string, fieldValue: any) => void;
}

function DynamicForm({ page, handleFieldChange }: DynamicFormProps) {
  const navigate = useNavigate();

  const [dialogOpenStatus, setDialogOpenStatus] = useState<boolean>(false);

  const [operationSuccessModal, setOperationSuccessModal] =
    useState<boolean>(false);

  const [checkLiveStatusModal, setCheckLiveStatusModal] =
    useState<boolean>(false);

  const { projectId, project_type } = useParams();

  const projectContextInfo = useContext(ProjectContext);
  const { state: projectState, setState: setProjectState } = projectContextInfo;

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    // Handle form submission
    console.log("submit Values ====>", values);
    
    if(page === 'dataLabellingSelection' && project_type === 'instance_segmentation_-_(clarifier)' && values.src_dataset !== '') {
      globalThis.window.location.href = `/segmentation?project_id=${projectId}&src_dataset=${values.src_dataset}&project_type=${project_type}`
    }
    
    if(page === 'dataLabellingSelection' && project_type === 'semantic_segmentation_-_(clarifier)' && values.src_dataset !== '') {
      globalThis.window.location.href = `/segmentation?project_id=${projectId}&src_dataset=${values.src_dataset}&project_type=${project_type}`
    }
    
    values["project_id"] = projectId;
    // Over here we will get api function to be call
    const apiToCall = callDynamicFormApi(page);
    // Calling the api
    
    apiToCall({...values, project_type})
    .then((x: any) => {
      console.log("Response from api", x);
      // Reset the form after success
      actions.resetForm();
      // Enable the form
      actions.setSubmitting(false);
      // Need to show the success modal
      if (page === "dataLabellingSelection") {
        handleDatalabellingSelectionResult(values, x);
        return;
      }
      setProjectState({
        ...projectState,
        processId: x.process_id,
      });
      setOperationSuccessModal(true);
      // show a notification progress has been save
      showSuccessSnacbar(x.message);
    })
    .catch((e: any) => {
      // Over here we need to handle the status codes ===>
      console.log("THis is the rror coming", e);
      // showErrorSnackbar
      if (e.status === 400) {
        showErrorSnackbar(e.error.message);
      }
      actions.setSubmitting(false);
    });
  };

  const handleDatalabellingSelectionResult = (values: any, res: any) => {
    const _labels = res?.data?.classes ?? [];
    const _imageIds = res?.data?.image_ids ?? [];
    console.log("values=====>", values);
    const _src_dataset = values?.src_dataset;
    setProjectState({
      ...projectState,
      labels: _labels,
      imageIds: _imageIds,
      src_dataset: _src_dataset,
    });
    navigate(
      `/${project_type}/operations/${projectId}/data-labelling/selection?fullScreen=true`
    );
  };

  const formik = useFormik({
    initialValues: getDynamicFormInitialValues(page),
    validationSchema: getDynamicFormValidationSchema(page, project_type), // We just need to handle the logic for the validation schema passed here  **** VERY VERY IMPORTANT *****
    onSubmit: handleSubmit,
  });

  const {
    handleSubmit: handleSub,
    isSubmitting,
    errors,
    values,
  } = formik || {};

  const handleDialogClose = () => {
    setDialogOpenStatus(false);
  };

  const handleOperationModalClose = () => {
    setOperationSuccessModal(false);
  };

  const handleCheckLiveStatusModalClose = () => {
    setCheckLiveStatusModal(false);
  };

  const handleCheckLiveStatus = () => {
    // First we close the operation successful Modal
    setOperationSuccessModal(false);
    setCheckLiveStatusModal(true);
  };

  const renderFormFieldsValues = {
    showModalFields: false,
    page: page,
    formik: formik,
    handleFieldChange: handleFieldChange,
    setDialogOpenStatus: setDialogOpenStatus,
  };

  console.log("errors from the formik", errors, values);

  return (
    <>
      <form onSubmit={handleSub} style={{ display: "inline-grid" }}>
        {renderFormFields(renderFormFieldsValues)}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <CircularProgress color="primary" size={20} />
              <p style={{ margin: "0px 10px" }}>Starting</p>
            </>
          ) : (
            "Start"
          )}
        </Button>

        {/* Dialog for changing the defualt settings */}
        <Dialog
          open={dialogOpenStatus}
          onClose={handleDialogClose}
          fullWidth={true}
        >
          <Box sx={{ p: 2, display: "inline-grid" }}>
            <h4>Change Default Settings</h4>
            <div style={{ display: "inline-grid", marginTop: "10px" }}>
              {renderFormFields({
                ...renderFormFieldsValues,
                showModalFields: true,
              })}
            </div>
            <Button
              variant="contained"
              onClick={() => handleDialogClose()}
              color="primary"
              style={{ maxWidth: "100px" }}
            >
              Done
            </Button>
          </Box>
        </Dialog>

        {/* Dialog for showing the Operation Successful Modal */}
        <OperationSuccessfullyCompletedModal
          open={operationSuccessModal}
          close={handleOperationModalClose}
          handleCheckLiveStatus={handleCheckLiveStatus}
        />
        {/* Dialog for Showing the Check Live status  */}
        {checkLiveStatusModal && (
          <ProgressbarModal
            page={page}
            open={checkLiveStatusModal}
            close={handleCheckLiveStatusModalClose}
          />
        )}
      </form>
    </>
  );
}

export default React.memo(DynamicForm);
