import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import './login.scss'

function NewLogin() {
  
  const navigate = useNavigate();
  
  const [searchParams] = useSearchParams();
  
  const callSetCookiesApi = async () => {
    const token = searchParams.get('token')
    if(token) {
      const rawResponse = await fetch('https://avaswiftai-be-dev.avawatz.net/auth/setAuthCookies', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({genieToken: token})
      });
      
      const res = await rawResponse.json();
      if(res) navigate('/projects')
    } else {
      navigate('/projects')
    }
  }
  
  useEffect(() => {    
    callSetCookiesApi()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
    <>
      <div className='zoom-container'>
        <img src="/genie-logo.png" alt="Genie Logo" width={300}/>
      </div>
    </>
  )
}

export default NewLogin