import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';


function ResponsiveAppBar() {

  return (
    <AppBar position="static" style={{ backgroundColor: '#f04a23', height: '75px', width: '100%' }}>
      <Container style={{ display: 'flex', alignItems: 'center', padding: '0px', height: '100%', margin: 'auto', width: 0, minWidth: '100%' }}>
        <Typography
          component="a"
          href="/projects"
        >
          <Toolbar style={{ backgroundColor: '#f04a23', height: '100%', width: '200px', textAlign: 'center' }}>
            <img style={{ width: '-webkit-fill-available', height: '55px' }} src="/genie-logo.png" alt='Logo' />
          </Toolbar>
        </Typography>
        <Typography variant="h6" noWrap component="div" style={{ fontWeight: 900, color: 'white', margin: 'auto' }}>
          {`Projects`}
        </Typography>

        <Box style={{ padding: '12px' }}>
            <IconButton sx={{ p: 0, backgroundColor: 'white' }}>
              <Avatar> A </Avatar>
            </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={false}
          />
        </Box>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
