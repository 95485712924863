import services from '@services/index';
import Cookies from 'js-cookie';
import React, { JSX, useEffect } from 'react';

interface ProtectedRouteProps {
  path?: string;
  element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  
  const handleUnauthorizedNavigate = () => {
    const hostname = window.location.hostname
    if(hostname === 'localhost') {
      window.location.href = 'http://localhost:3000/auth'
    } else {
      window.location.href = 'https://avaswiftai-dev.avawatz.net/auth'
    }
  }
  
  useEffect(() => {  
    
    const backendApiUrl = Cookies.get('genieBackendApiUrl')
    
    if(!backendApiUrl) handleUnauthorizedNavigate()
    
    const verifyAuth = async () => {
      try {
         await services.get('/auth/avaswift/verify');
      } catch (error) {
        handleUnauthorizedNavigate()
      }
    };

    verifyAuth();
  }, []);
  
  return (
    <>{element}</>
  )
  
};

export default ProtectedRoute;