export const modelsList: any = {
  diverseSelection: [
    {
      label: "resnet18",
      value: "resnet18",
    },
    {
      label: "resnet34",
      value: "resnet34",
    },
    {
      label: "resnet50",
      value: "resnet50",
    },
    {
      label: "resnet101",
      value: "resnet101",
    },
    {
      label: "resnet152",
      value: "resnet152",
    },
    {
      label: "efficientnet_b0",
      value: "efficientnet_b0",
    },
    {
      label: "efficientnet_b1",
      value: "efficientnet_b1",
    },
    {
      label: "efficientnet_b2",
      value: "efficientnet_b2",
    },
    {
      label: "efficientnet_b3",
      value: "efficientnet_b3",
    },
    {
      label: "efficientnet_b4",
      value: "efficientnet_b4",
    },
    {
      label: "efficientnet_b5",
      value: "efficientnet_b5",
    },
    {
      label: "efficientnet_b6",
      value: "efficientnet_b6",
    },
    {
      label: "efficientnet_b7",
      value: "efficientnet_b7",
    },
    {
      label: "alexnet",
      value: "alexnet",
    },
    {
      label: "vgg",
      value: "vgg",
    },
    {
      label: "densenet",
      value: "densenet",
    },
  ],
  // We have rename the below from training to objectDetectionTraining
  objectDetectionTraining: [
    {
      label: "YOLOv7",
      value: "yolov7",
    },
    {
      label: "YOLOv7-X",
      value: "yolov7x",
    },
    {
      label: "YOLOv7-W6",
      value: "yolov7-w6",
    },
    {
      label: "YOLOv7-E6",
      value: "yolov7-e6",
    },
    {
      label: "YOLOv7-D6",
      value: "yolov7-d6",
    },
    {
      label: "YOLOv7-E6E",
      value: "yolov7-e6e",
    },
    // From here is the new added list
    {
      label: "Faster-RCNN-R50-1x",
      value: "Faster-RCNN-R50-1x",
    },
    {
      label: "RPN-x101-64x4d-FPN-2x",
      value: "RPN-x101-64x4d-FPN-2x",
    },
    {
      label: "SSD-512",
      value: "SSD-512",
    },
    {
      label: "Retinanet-x101-64x4d-FPN-1x",
      value: "Retinanet-x101-64x4d-FPN-1x",
    },
    {
      label: "Cascade-RCNN-x101-64x4d-FPN-1x",
      value: "Cascade-RCNN-x101-64x4d-FPN-1x",
    },
    {
      label: "YOLOV3-DARKNET53-608",
      value: "YOLOV3-DARKNET53-608",
    },
    {
      label: "Cornernet_hourglass104_8xb6",
      value: "Cornernet_hourglass104_8xb6",
    },
    {
      label: "Grid-RCNN-x101-64x4d-FPN",
      value: "Grid-RCNN-x101-64x4d-FPN",
    },
    {
      label: "GA-RPN-X101-64x4d-FPN-1x",
      value: "GA-RPN-X101-64x4d-FPN-1x",
    },
    {
      label: "GA-Faster-RCNN-X-101-64x4d-FPN-1x",
      value: "GA-Faster-RCNN-X-101-64x4d-FPN-1x",
    },
    {
      label: "GA-RetinaNet-X-101-64x4d-FPN-1x",
      value: "GA-RetinaNet-X-101-64x4d-FPN-1x",
    },
    {
      label: "FSAF-X101-64x4d-FPN-1x",
      value: "FSAF-X101-64x4d-FPN-1x",
    },
    {
      label: "Centernet-update-r50-caffe-FPN",
      value: "Centernet-update-r50-caffe-FPN",
    },
    {
      label: "Libra R-CNN-X-101-64x4d-FPN",
      value: "Libra R-CNN-X-101-64x4d-FPN",
    },
    {
      label: "Tridentnet-r50-caffee-MS-3x",
      value: "Tridentnet-r50-caffee-MS-3x",
    },
    {
      label: "FCOS_X101-64x4d-FPN",
      value: "FCOS_X101-64x4d-FPN",
    },
    {
      label: "FCOS_R50",
      value: "FCOS_R50",
    },
    {
      label: "RepPoints-R-101-FPN",
      value: "RepPoints-R-101-FPN",
    },
    {
      label: "freeanchor_x_101-32x4d",
      value: "freeanchor_x_101-32x4d",
    },
    {
      label: "Cascade-RPN-R-50-FPN-caffe",
      value: "Cascade-RPN-R-50-FPN-caffe",
    },
    {
      label: "Fovea_r101_fpn_4xb4-2x",
      value: "Fovea_r101_fpn_4xb4-2x",
    },
    {
      label: "double_head-r50-FPN-1x",
      value: "double_head-r50-FPN-1x",
    },
    {
      label: "ATS-r101-FPN-1x",
      value: "ATS-r101-FPN-1x",
    },
    {
      label: "NAS-FCOS-R50-caffe",
      value: "NAS-FCOS-R50-caffe",
    },
    {
      label: "Centripetalnet_hourglass104_16xb6",
      value: "Centripetalnet_hourglass104_16xb6",
    },
    {
      label: "Autoassign_r50_caffe_FPN",
      value: "Autoassign_r50_caffe_FPN",
    },
    {
      label: "Sabl-cascade-rcnn-r101-FPN",
      value: "Sabl-cascade-rcnn-r101-FPN",
    },
    {
      label: "Sabl-retinanet-r101-640_800-2x",
      value: "Sabl-retinanet-r101-640_800-2x",
    },
    {
      label: "Dynamic-rcnn-r50_FPN-1x",
      value: "Dynamic-rcnn-r50_FPN-1x",
    },
    {
      label: "Detr-R50-150e",
      value: "Detr-R50-150e",
    },
    {
      label: "paa_r101_FPN-2x",
      value: "paa_r101_FPN-2x",
    },
    {
      label: "VFNET_x101_64x4d",
      value: "VFNET_x101_64x4d",
    },
    {
      label: "Sparsercnn_r101_FPN-3x",
      value: "Sparsercnn_r101_FPN-3x",
    },
    {
      label: "Yolof_r50_c5_8xb8",
      value: "Yolof_r50_c5_8xb8",
    },
    {
      label: "YoloX-x-8xb8",
      value: "YoloX-x-8xb8",
    },
    {
      label: "Deformable-dtr-twostage-R50",
      value: "Deformable-dtr-twostage-R50",
    },
    {
      label: "tood_r101_FPN_MS_2x",
      value: "tood_r101_FPN_MS_2x",
    },
    {
      label: "ddod-r50-fpn_1x",
      value: "ddod-r50-fpn_1x",
    },
    {
      label: "RTMdet-X-8xb32",
      value: "RTMdet-X-8xb32",
    },
    {
      label: "Conditional_detr-r50-8xb2",
      value: "Conditional_detr-r50-8xb2",
    },
    {
      label: "Dab_detr_r50_8xb2-50e",
      value: "Dab_detr_r50_8xb2-50e",
    },
    {
      label: "Dino_swin-l-8xb2",
      value: "Dino_swin-l-8xb2",
    },
    {
      label: "Glip_atss_swin-t",
      value: "Glip_atss_swin-t",
    },
  ],
  imageClassificationTraining: [
    {
      label: "AlexNet",
      value: "AlexNet",
    },
    {
      label: "ConvNeXt-Base",
      value: "ConvNeXt-Base",
    },
    {
      label: "ConvNeXt-Tiny",
      value: "ConvNeXt-Tiny",
    },
    {
      label: "ConvNeXt-Small",
      value: "ConvNeXt-Small",
    },
    {
      label: "ConvNeXt-Large",
      value: "ConvNeXt-Large",
    },
    {
      label: "DenseNet121",
      value: "DenseNet121",
    },
    {
      label: "DenseNet161",
      value: "DenseNet161",
    },
    {
      label: "DenseNet169",
      value: "DenseNet169",
    },
    {
      label: "DenseNet201",
      value: "DenseNet201",
    },
    {
      label: "EfficientNet-B0",
      value: "EfficientNet-B0",
    },
    {
      label: "EfficientNet-B1",
      value: "EfficientNet-B1",
    },
    {
      label: "EfficientNet-B2",
      value: "EfficientNet-B2",
    },
    {
      label: "EfficientNet-B3",
      value: "EfficientNet-B3",
    },
    {
      label: "EfficientNet-B4",
      value: "EfficientNet-B4",
    },
    {
      label: "EfficientNet-B5",
      value: "EfficientNet-B5",
    },
    {
      label: "EfficientNet-B6",
      value: "EfficientNet-B6",
    },
    {
      label: "EfficientNet-B7",
      value: "EfficientNet-B7",
    },
    {
      label: "EfficientNet-V2-S",
      value: "EfficientNet-V2-S",
    },
    {
      label: "EfficientNet-V2-M",
      value: "EfficientNet-V2-M",
    },
    {
      label: "EfficientNet-V2-L",
      value: "EfficientNet-V2-L",
    },
    {
      label: "GoogLeNet",
      value: "GoogLeNet",
    },
    {
      label: "Inception-V3",
      value: "Inception-V3",
    },
    {
      label: "MNASNet0-5",
      value: "MNASNet0-5",
    },
    {
      label: "MNASNet0-75",
      value: "MNASNet0-75",
    },
    {
      label: "MNASNet1-0",
      value: "MNASNet1-0",
    },
    {
      label: "MNASNet1-3",
      value: "MNASNet1-3",
    },
    {
      label: "MobileNet-V2",
      value: "MobileNet-V2",
    },
    {
      label: "MobileNet-V3-Large",
      value: "MobileNet-V3-Large",
    },
    {
      label: "MobileNet-V3-Small",
      value: "MobileNet-V3-Small",
    },
    {
      label: "RegNet-Y-400MF",
      value: "RegNet-Y-400MF",
    },
    {
      label: "RegNet-Y-800MF",
      value: "RegNet-Y-800MF",
    },
    {
      label: "RegNet-Y-1-6GF",
      value: "RegNet-Y-1-6GF",
    },
    {
      label: "RegNet-Y-32GF",
      value: "RegNet-Y-32GF",
    },
    {
      label: "RegNet-Y-8GF",
      value: "RegNet-Y-8GF",
    },
    {
      label: "RegNet-Y-16GF",
      value: "RegNet-Y-16GF",
    },
    {
      label: "RegNet-Y-128GF",
      value: "RegNet-Y-128GF",
    },
    {
      label: "RegNet-X-400MF",
      value: "RegNet-X-400MF",
    },
    {
      label: "RegNet-X-800MF",
      value: "RegNet-X-800MF",
    },
    {
      label: "RegNet-X-1-6GF",
      value: "RegNet-X-1-6GF",
    },
    {
      label: "RegNet-X-3-2GF",
      value: "RegNet-X-3-2GF",
    },
    {
      label: "RegNet-X-8GF",
      value: "RegNet-X-8GF",
    },
    {
      label: "RegNet-X-16GF",
      value: "RegNet-X-16GF",
    },
    {
      label: "RegNet-X-32GF",
      value: "RegNet-X-32GF",
    },
    {
      label: "ResNet18",
      value: "ResNet18",
    },
    {
      label: "ResNet34",
      value: "ResNet34",
    },
    {
      label: "ResNet50",
      value: "ResNet50",
    },
    {
      label: "ResNet101",
      value: "ResNet101",
    },
    {
      label: "ResNet152",
      value: "ResNet152",
    },
    {
      label: "ResNeXt50-32X4D",
      value: "ResNeXt50-32X4D",
    },
    {
      label: "ResNeXt101-32X8D",
      value: "ResNeXt101-32X8D",
    },
    {
      label: "ResNeXt101-64X4D",
      value: "ResNeXt101-64X4D",
    },
    {
      label: "ShuffleNet-V2-X0-5",
      value: "ShuffleNet-V2-X0-5",
    },
    {
      label: "ShuffleNet-V2-X1-0",
      value: "ShuffleNet-V2-X1-0",
    },
    {
      label: "ShuffleNet-V2-X1-5",
      value: "ShuffleNet-V2-X1-5",
    },
    {
      label: "ShuffleNet-V2-X2-0",
      value: "ShuffleNet-V2-X2-0",
    },
    {
      label: "SqueezeNet1-0",
      value: "SqueezeNet1-0",
    },
    {
      label: "SqueezeNet1-1",
      value: "SqueezeNet1-1",
    },
    {
      label: "Swin-T",
      value: "Swin-T",
    },
    {
      label: "Swin-S",
      value: "Swin-S",
    },
    {
      label: "Swin-B",
      value: "Swin-B",
    },
    {
      label: "VGG11",
      value: "VGG11",
    },
    {
      label: "VGG11-BN",
      value: "VGG11-BN",
    },
    {
      label: "VGG13",
      value: "VGG13",
    },
    {
      label: "VGG13-BN",
      value: "VGG13-BN",
    },
    {
      label: "VGG16",
      value: "VGG16",
    },
    {
      label: "VGG16-BN",
      value: "VGG16-BN",
    },
    {
      label: "VGG19",
      value: "VGG19",
    },
    {
      label: "VGG19-BN",
      value: "VGG19-BN",
    },
    {
      label: "ViT-B-16",
      value: "ViT-B-16",
    },
    {
      label: "ViT-B-32",
      value: "ViT-B-32",
    },
    {
      label: "ViT-L-16",
      value: "ViT-L-16",
    },
    {
      label: "ViT-L-32",
      value: "ViT-L-32",
    },
    {
      label: "ViT-H-14",
      value: "ViT-H-14",
    },
    {
      label: "Wide-ResNet50-2",
      value: "Wide-ResNet50-2",
    },
    {
      label: "Wide-ResNet101-2",
      value: "Wide-ResNet101-2",
    },
  ],
  
  clarifierModelObjDetTraining: [
    {
      label: "YOLOv8",
      value: 'YOLOv8'
    },
    {
      label: "YOLOv8n",
      value: "YOLOv8n"
    },
    {
      label: "YOLOv8s",
      value: "YOLOv8s"
    },
    {
      label: "YOLOv8m",
      value: "YOLOv8m"
    },
    {
      label: "YOLOv8l",
      value: "YOLOv8l"
    },
  ],
  clarifierModelSemSegTraining: [
    { label: "Unet", value: "Unet" },
    { label: "UnetPlusPlus", value: "UnetPlusPlus" },
    { label: "FPN", value: "FPN" },
    { label: "PSPNet", value: "PSPNet" },
    { label: "DeepLabV3", value: "DeepLabV3" },
    { label: "DeepLabV3Plus", value: "DeepLabV3Plus" },
    { label: "Linknet", value: "Linknet" },
    { label: "MAnet", value: "MAnet" },
    { label: "PAN", value: "PAN" },
    { label: "UPerNet", value: "UPerNet" }
  ]
};
