import { SelectProps } from "@mui/material";
import CustomDropdownField from "../../../CustomDropdownField";
import React from "react";

interface CustomDataSetFieldProps extends SelectProps {
  formik: any;
}

function CustomClarifierDataSetField(props: CustomDataSetFieldProps) {
  const datasetList = [
    {
      label: "None",
      value: "",
    },
    {
      label: "Labelled Set",
      value: "labelled_set",
    },
    {
      label: "Unlabelled Set",
      value: "unlabelled_set",
    },
    {
      label: "Augmentation Set",
      value: "augmented_set",
    },
    {
      label: "Easy Selected Set",
      value: "easy_selected_set",
    },
    {
      label: "Medium Selected Set",
      value: "medium_selected_set",
    },
    {
      label: "Hard Selected Set",
      value: "hard_selected_set",
    },
    {
      label: "Initial Selected Set",
      value: "initial_selected_set"
    },
    {
      label: "Evaluation Set",
      value: "evaluation_set"
    },
    {
      label: "Targeted Set",
      value: "targeted_set"
    }
  ];

  console.log("props from the dataset field", props);

  return (
    <CustomDropdownField
      dropdownData={datasetList}
      key={Math.random()}
      {...props}
    />
  );
}

export default React.memo(CustomClarifierDataSetField);
